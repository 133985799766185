import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import { Icon } from "@iconify/react";
import team2 from "assets/images/team-2.jpg";
import { useNavigate } from "react-router-dom";

const SubCategory = ({ image, name }) => (
  <MDBox display="flex" alignItems="center" lineHeight={1}>
    <MDAvatar src={image?.[0] || team2} name={name || "Unnamed"} size="sm" />
    <MDBox ml={2} lineHeight={1}>
      <MDTypography display="block" variant="button" fontWeight="medium">
        {name || "Unnamed SubCategory"}
      </MDTypography>
    </MDBox>
  </MDBox>
);

const SubCategoryDesc = ({ title, description }) => (
  <MDBox lineHeight={1} textAlign="left">
    {/* <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
      {title || "No Title"}
    </MDTypography> */}
    <MDTypography variant="caption">{description || "No Description"}</MDTypography>
  </MDBox>
);

export default function data(subCategoryData, handleDeleteClick) {
  const navigate = useNavigate();

  const columns = [
    { Header: "Name", accessor: "subCategory", align: "left" },
    { Header: "Description", accessor: "description", align: "left" },
    { Header: "Plan Category", accessor: "plancategory", align: "center" },
    { Header: "Is Published", accessor: "ispublish", align: "center" },
    { Header: "Amount", accessor: "amount", align: "center" },
    { Header: "Total Amount", accessor: "totalamount", align: "center" },
    // { Header: "Tax Percentage", accessor: "taxpercentage", align: "center" },
    { Header: "Placeholder Amount", accessor: "placeholderamount", align: "center" },
    // { Header: "Valid Days", accessor: "validdays", align: "center" },
    { Header: "Plan Highlights", accessor: "planhighlights", align: "center" },
    { Header: "Plan Duration (hrs)", accessor: "planduration", align: "center" },
    { Header: "Pandit Samagri", accessor: "panditSamagri", align: "center" },
    { Header: "User Samagri", accessor: "userSamagri", align: "center" },
    { Header: "Action", accessor: "action", align: "center" },
  ];

  const rows =
    subCategoryData.results?.length > 0
      ? subCategoryData.results.map((item, index) => ({
          subCategory: <SubCategory image={item.image} name={item.name || "-"} />,
          description: (
            <SubCategoryDesc
              description={
                item.description ? (
                  <div dangerouslySetInnerHTML={{ __html: item.description }} />
                ) : (
                  "-"
                )
              }
            />
          ),
          ispublish: (
            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
              {item?.isPublished?.toString() || "-"}
            </MDTypography>
          ),
          totalamount: (
            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
              {item?.totalAmount || "-"}
            </MDTypography>
          ),
          // taxpercentage: (
          //   <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          //     {item?.taxPercentage || "-"}
          //   </MDTypography>
          // ),
          amount: (
            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
              {item?.amount || "-"}
            </MDTypography>
          ),
          placeholderamount: (
            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
              {item?.placeholderAmount || "-"}
            </MDTypography>
          ),
          validdays: (
            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
              {item?.validityDays || "-"}
            </MDTypography>
          ),
          planhighlights: (
            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
              {item?.planHighlights || "-"}
            </MDTypography>
          ),
          planduration: (
            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
              {item?.planDuration || "-"}
            </MDTypography>
          ),
          panditSamagri:
            item?.material?.length > 0 ? (
              <div className="w-40 h-24 p-2 overflow-y-scroll">
                <div className="flex mb-1 justify-between">
                  <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
                    Name
                  </MDTypography>
                  <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
                    Quantity
                  </MDTypography>
                </div>
                {item.material.map((materialItem, materialIndex) => (
                  <div key={`material-${materialIndex}`}>
                    {(materialItem?.panditSamgiri || []).map((data, index) => (
                      <div key={index} className="">
                        <div className="flex justify-between">
                          <div className="flex">
                            <h1 className="text-sm font-bold mr-1">{index + 1}.</h1>
                            <MDTypography
                              display="block"
                              variant="caption"
                              color="text"
                              fontWeight="medium"
                            >
                              {data?.name || "-"}
                            </MDTypography>
                          </div>
                          <MDTypography
                            display="block"
                            variant="caption"
                            color="text"
                            fontWeight="medium"
                          >
                            {data?.quantity || "-"}
                          </MDTypography>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            ) : (
              <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
                -
              </MDTypography>
            ),
          userSamagri:
            item?.material?.length > 0 ? (
              <div className="w-40 h-24 p-2 overflow-y-scroll">
                <div className="flex mb-1 justify-between">
                  <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
                    Name
                  </MDTypography>
                  <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
                    Quantity
                  </MDTypography>
                </div>
                {item.material.map((materialItem, materialIndex) => (
                  <div key={`material-${materialIndex}`}>
                    {(materialItem?.userSamgiri || []).map((data, index) => (
                      <div key={index} className="">
                        <div className="flex justify-between">
                          <div className="flex">
                            <h1 className="text-sm font-bold mr-1">{index + 1}.</h1>
                            <MDTypography
                              display="block"
                              variant="caption"
                              color="text"
                              fontWeight="medium"
                            >
                              {data?.name || "-"}
                            </MDTypography>
                          </div>
                          <MDTypography
                            display="block"
                            variant="caption"
                            color="text"
                            fontWeight="medium"
                          >
                            {data?.quantity || "-"}
                          </MDTypography>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            ) : (
              <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
                -
              </MDTypography>
            ),

          plancategory: (
            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
              {item?.planCategory?.name || "-"}
            </MDTypography>
          ),
          action: (
            <MDTypography className="flex">
              <Icon
                className="text-black mr-1"
                icon="ep:edit"
                onClick={() =>
                  navigate("/yajya/sub-category/add-sub-category", {
                    state: { item, isEdit: true },
                  })
                }
              />
              <Icon
                className="text-red-800"
                icon="ep:delete"
                onClick={() => handleDeleteClick(item)}
              />
            </MDTypography>
          ),
        }))
      : [
          {
            subCategory: (
              <MDTypography
                variant="h6"
                color="text"
                fontWeight="medium"
                style={{ textAlign: "center", padding: "20px" }}
                colSpan={columns.length}
              >
                No Data Found
              </MDTypography>
            ),
            description: "",
            ispublish: "",
            totalamount: "",
            // taxpercentage: "",
            amount: "",
            placeholderamount: "",
            validdays: "",
            planhighlights: "",
            planduration: "",
            panditSamagri: "",
            userSamagri: "",
            plancategory: "",
            action: "",
          },
        ];

  return {
    columns,
    rows,
  };
}
