import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import { useForm, FormProvider, Controller } from "react-hook-form";
import ConfirmDelete from "../../shared/deleteDialog/index";
import { RiDeleteBinLine } from "react-icons/ri";
import AddBanner from "../addBanner/index";
import {
  useLazyGetBannerQuery,
  useDeleteBannerMutation,
  useDeactivateBannerMutation,
} from "../../redux/api/bannerApi";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { Button, Typography } from "@mui/material";
import Switch from "@mui/material/Switch";
import useFilter from "utility/useFilter";
import { useDebounce } from "@uidotdev/usehooks";
import { customToastStyle } from "../../redux/api";
import noDataImage from "../../assets/images/noData/nodata.png";

function hasHtmlTags(str) {
  const htmlTagRegex = /<\/?[a-z][\s\S]*>/i;
  return htmlTagRegex.test(str);
}

function Banner() {
  const methods = useForm();
  const [bannerList, setBannerList] = useState([]);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [addBanner, setAddBanner] = useState(false);
  const [selectedBannerId, setSelectedBannerId] = useState(null);
  const [getBanner] = useLazyGetBannerQuery();
  const [deleteBanner, { isLoading: isDeleteBannerLoading }] = useDeleteBannerMutation();
  const [deactivateBanner, { isLoading: isDeactivateBannerLoading }] =
    useDeactivateBannerMutation();
  const [searchValue, setSearchValue] = useState();
  const debouncedSearchTerm = useDebounce(searchValue, 800);
  const { filters, setFilters } = useFilter();

  useEffect(() => {
    setFilters((prevValue) => ({ ...prevValue, name: debouncedSearchTerm, page }));
  }, [debouncedSearchTerm, page]);

  const handleAction = (type, id = null) => {
    switch (type) {
      case "OPEN_DELETE":
        setIsDeleteOpen(true);
        setSelectedBannerId(id);
        break;
      case "CLOSE_DELETE":
        setIsDeleteOpen(false);
        setSelectedBannerId(null);
        break;
      case "CONFIRM_DELETE":
        if (selectedBannerId) {
          handleDeleteBanner(selectedBannerId);
        }
        setIsDeleteOpen(false);
        break;
      case "OPEN_ADD_BANNER":
        setAddBanner(true);
        break;
      case "CLOSE_ADD_BANNER":
        setAddBanner(false);
        methods.reset();
        break;
      default:
        console.warn("Unknown action type:", type);
    }
  };

  const handleDeleteBanner = async (id) => {
    try {
      const bannerDeleteResponse = await deleteBanner(id).unwrap();
      if (!bannerDeleteResponse?.success) {
        toast.error(`${bannerDeleteResponse.data.message || "Something went wrong!"} `, {
          style: customToastStyle.error,
        });
      }
    } catch (err) {
      console.error("Failed to delete banner:", err);
    }
  };

  const handleChange = (e, p) => {
    setPage(p);
  };

  useEffect(() => {
    const fetchBannerData = async () => {
      const bannerData = await getBanner(filters);
      if (bannerData?.data?.success) setBannerList(bannerData?.data?.data);
    };
    fetchBannerData();
  }, [filters, isDeleteBannerLoading, isDeactivateBannerLoading, page]);

  const handleIsActiveClick = async (id) => {
    try {
      const bannerDeactivateResponse = await deactivateBanner(id).unwrap();
      if (!bannerDeactivateResponse?.success) {
        toast.error(`${bannerDeactivateResponse.data.message || "Something went wrong!"} `, {
          style: customToastStyle.error,
        });
      }
    } catch (err) {
      console.error("Failed to delete banner:", err);
    }
  };

  return (
    <FormProvider {...methods}>
      <>
        <div className="flex mb-2 justify-end">
          <Button variant="addButton" type="button" onClick={() => handleAction("OPEN_ADD_BANNER")}>
            Add Banner
          </Button>
        </div>
        <MDBox className="grid grid-cols-3 gap-3">
          {((bannerList && bannerList.results) || []).length > 0 ? (
            bannerList.results.map((item, index) => (
              <Card
                style={{
                  boxShadow: "0px 4px 10px rgba(242, 111, 34, 0.2)",
                }}
                className="inset-0 relative p-3"
                key={index}
              >
                <img
                  src={item.image}
                  alt=""
                  className="h-52 transition-transform duration-300 ease-in-out transform hover:scale-110 rounded-lg w-auto"
                />
                <div className="flex pt-2 justify-between">
                  <h5 className="font-bold">{item.name}</h5>
                  <span onClick={() => handleAction("OPEN_DELETE", item.id)} className="pt-1">
                    <RiDeleteBinLine className="text-red-700 w-5 h-5" />
                  </span>
                </div>
                <MDBox className="flex justify-between" display="flex">
                  <h2 className="text-sm pt-2 text-black">isActive</h2>
                  <Switch
                    onClick={() => handleIsActiveClick(item.id)}
                    checked={item.isActive}
                    defaultChecked
                    color="warning"
                    sx={{
                      "& .MuiSwitch-track": {
                        backgroundColor: item.isActive ? "green !important" : "red !important",
                        opacity: 1,
                      },
                    }}
                  />
                </MDBox>
                <div className="flex">
                  <h5 className="text-sm flex w-28">Description:-</h5>
                  <p className="text-xs p-[0.5%] ml-2">
                    {item.description ? (
                      hasHtmlTags(item.description) ? (
                        <div dangerouslySetInnerHTML={{ __html: item.description }} />
                      ) : (
                        item.description
                      )
                    ) : (
                      "-"
                    )}
                  </p>
                </div>
              </Card>
            ))
          ) : (
            <div className="flex items-center justify-center w-max m-52">
              <img src={noDataImage} alt="No Banners Available" className="h-52 rounded-lg" />
            </div>
          )}
        </MDBox>
        <div className="bg-orange-500">
          <ConfirmDelete
            title=""
            fullMessage={"Are you sure you want to delete this banner?"}
            handleClose={() => handleAction("CLOSE_DELETE")}
            deleteHandler={() => handleAction("CONFIRM_DELETE")}
            open={isDeleteOpen}
          />
        </div>
        {addBanner && (
          <AddBanner
            Controller={Controller}
            methods={methods}
            open={addBanner}
            onClose={() => handleAction("CLOSE_ADD_BANNER")}
            setBannerList={setBannerList}
          />
        )}

        {/* Only show pagination if there are banners */}
        {bannerList?.results?.length > 0 && (
          <Typography variant="paginationAlign" component="div">
            <Stack spacing={2}>
              <Pagination
                count={bannerList?.totalPages}
                defaultPage={page}
                onChange={handleChange}
                variant="outlined"
              />
            </Stack>
          </Typography>
        )}
      </>
    </FormProvider>
  );
}

export default Banner;
