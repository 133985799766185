import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useFormContext } from "react-hook-form";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
} from "@mui/material";
import { Icon } from "@iconify/react";
import Switch from "@mui/material/Switch";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import RHFTextField from "../../../hook-form/RHFTextField";
import RHFImageUpload from "hook-form/RHFImageUpload";
import RichTextEditor from "shared/RichTextEditor";

const AddCategoryDialog = ({ open, onClose, editData, title, onSubmit, Controller, methods }) => {
  const [image, setImage] = useState("");
  const [isToggled, setIsToggled] = useState(false);
  const { register, handleSubmit, setValue } = useFormContext();

  useEffect(() => {
    if (editData) {
      setValue("name", editData?.name || "");
      setValue("description", editData?.description || "");
      setValue("image", editData?.image || "");
      setIsToggled(editData?.isPublished || false);
    }
  }, [editData, setValue]);

  // useEffect(()=>{
  //    setValue("name",)
  // },[])
  // const handleInputChange = (event) => {
  //   const { files } = event.target;
  //   if (files && files.length > 0) {
  //     const file = files[0];
  //     if (file) {
  //       setImage(URL.createObjectURL(file));
  //     }
  //   }
  // };

  const handleSubmitClick = (data) => {
    console.log(data, "qefe");
    const category = {
      name: data.name || "",
      image: data.image || "",
      description: data.description || "",
      isPublished: isToggled || false,
    };
    onSubmit(category);
    onClose();
  };

  const handleToggle = () => setIsToggled(!isToggled);

  return (
    <Dialog open={open} onClose={onClose}>
      <div className="w-96">
        <DialogTitle sx={{ color: "#FD8D4C" }}>{title}</DialogTitle>
        <form onSubmit={handleSubmit(handleSubmitClick)}>
          <DialogContent>
            <RHFImageUpload
              defaultValue={editData?.image}
              name="image"
              label="Upload Image"
              required
            />

            <Box sx={{ display: "flex" }}>
              <h6>Name</h6> &nbsp;<span style={{ color: "red" }}>*</span>
            </Box>
            <RHFTextField name="name" placeholder="Enter name" type="text" fullWidth required />
            <h6 className="mt-2">Description</h6>
            <Controller
              name="description"
              control={methods.control}
              render={({ field }) => (
                <RichTextEditor value={field.value} onChange={field.onChange} />
              )}
            />
            {/* <TextField
            margin="dense"
            label="Description"
            name="description"
            type="text"
            fullWidth
            variant="outlined"
            multiline
            rows={4}
            {...register("description")}
          /> */}
            <MDBox
              className="flex justify-between"
              {...register("publish")}
              display="flex"
              alignItems="center"
            >
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleToggle}
                sx={{ cursor: "pointer", userSelect: "none" }}
              >
                &nbsp;&nbsp;Publish :
              </MDTypography>
              <Switch
                checked={isToggled}
                onChange={handleToggle}
                sx={{
                  "& .MuiSwitch-track": {
                    backgroundColor: isToggled ? "green !important" : "red !important",
                    opacity: 1,
                  },
                  "& .Mui-checked + .MuiSwitch-track": {
                    backgroundColor: "green !important",
                  },
                  "& .MuiSwitch-thumb": {
                    color: "white",
                  },
                  "& .MuiSwitch-thumb.Mui-checked": {
                    color: "white",
                  },
                }}
              />
            </MDBox>
          </DialogContent>
          <DialogActions>
            <Button variant="submit" onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit" variant="submit">
              Submit
            </Button>
          </DialogActions>
        </form>
      </div>
    </Dialog>
  );
};

export default AddCategoryDialog;
