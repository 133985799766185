import React from "react";
import { useForm, FormProvider, Controller } from "react-hook-form";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import BG from "../../../assets/images/logos/Back1.jpg";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetCategoryQuery } from "../../../redux/api/categoryApi";
import {
  useAddSubCategoryMutation,
  useUpdateSubCategoryMutation,
} from "../../../redux/api/subCategoryApi";
import RHFFormImageUpload from "../../../hook-form/RHFFormImageUpload";
import RHFTextField from "../../../hook-form/RHFTextField";
import { RHFSelect } from "../../../hook-form/RHFSelect";
import useFilter from "utility/useFilter";
import toast from "react-hot-toast";
import { customToastStyle } from "../../../redux/api";
import RichTextEditor from "../../../shared/RichTextEditor";

const Index = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { item } = location.state || {};
  const { filters } = useFilter();
  const { data: categoryData } = useGetCategoryQuery(filters);

  const [addSubCategory] = useAddSubCategoryMutation();
  const [updateSubCategory] = useUpdateSubCategoryMutation();

  const categoryOptions = categoryData?.data?.results?.map((category) => ({
    label: category.name,
    value: category.id,
  }));

  const methods = useForm({
    defaultValues: {
      name: item?.name,
      planCategory: item?.planCategory?.id,
      image: item?.image[0] || "",
      description: item?.description || "",
      isPublished: item?.isPublished,
      totalAmount: item?.totalAmount,
      amount: item?.amount,
      placeholderAmount: item?.placeholderAmount,
      validityDays: item?.validityDays,
      planHighlights: item?.planHighlights,
      planDuration: item?.planDuration,
      // taxPercentage: item?.taxPercentage,
    },
  });

  const handleSubmitClick = async (data) => {
    console.log(data, "data");
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      if (value !== undefined && value !== null) {
        // Only append if value is not undefined or null
        formData.append(key, value);
      }
    });

    try {
      if (item?.id) {
        const subCategoryResponse = await updateSubCategory({
          id: item.id,
          formData,
        });
        if (!subCategoryResponse?.data?.success) {
          toast.error(`${subCategoryResponse.data.message || "Something went wrong!"} `, {
            style: customToastStyle.error,
          });
        }
        if (subCategoryResponse?.data?.success) navigate("/yajya/sub-category");
      } else {
        const response = await addSubCategory({
          id: data.planCategory,
          formData,
        });
        if (!response?.data?.success) {
          toast.error(`${response.data.message || "Something went wrong!"} `, {
            style: customToastStyle.error,
          });
        }
        if (response?.data?.success) navigate("/yajya/sub-category");
      }
    } catch (err) {
      console.error("Failed to process sub-category:", err);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmitClick)}>
        <Card>
          <Box
            sx={{
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              backgroundImage: `url(${BG})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundAttachment: "fixed",
              padding: "10px",
            }}
            noValidate
            autoComplete="off"
          >
            <Card className="">
              <RHFFormImageUpload
                defaultValue={item?.image[0]}
                name="image"
                label="Image"
                required
              />
            </Card>

            <Box sx={{ marginTop: "30px", display: "block" }}>
              <label className="black text-sm">Plan Category</label>
              &nbsp;<span style={{ color: "red" }}>*</span>
            </Box>

            <RHFSelect name="planCategory" options={categoryOptions} required />

            <Box>
              <label className="black text-sm">Name</label>
              &nbsp;<span style={{ color: "red" }}>*</span>
            </Box>

            <RHFTextField name="name" placeholder="Enter Name" type="text" fullWidth required />

            <label className="black text-sm">Description</label>
            <Controller
              name="description"
              control={methods.control}
              render={({ field }) => (
                <RichTextEditor value={field.value} onChange={field.onChange} />
              )}
            />

            {/* <RHFTextField
              name="description"
              placeholder="Enter Description"
              type="text"
              fullWidth
              required
            /> */}
            <div className="grid grid-cols-3 gap-3">
              <div>
                <Box>
                  <label className="black text-sm">IsPublished</label>
                  &nbsp;<span style={{ color: "red" }}>*</span>
                </Box>
                <RHFSelect
                  name="isPublished"
                  options={[
                    { label: "True", value: "true" },
                    { label: "False", value: "false" },
                  ]}
                  required
                />
              </div>
              <div>
                <Box>
                  <label className="black text-sm">Total Amount</label>
                  &nbsp;<span style={{ color: "red" }}>*</span>
                </Box>

                <RHFTextField
                  name="totalAmount"
                  placeholder="Total Amount"
                  type="number"
                  fullWidth
                  required
                />
              </div>
              <div>
                <Box>
                  <label className="black text-sm">Pandit Payout Amount</label>
                  &nbsp;<span style={{ color: "red" }}>*</span>
                </Box>

                <RHFTextField name="amount" placeholder="amount" type="number" fullWidth required />
              </div>
            </div>
            <div className="grid grid-cols-3 gap-3">
              <div>
                <label className="black text-sm">Placeholder Amount</label>

                <RHFTextField
                  name="placeholderAmount"
                  placeholder="Placeholder Amount"
                  type="number"
                  fullWidth
                />
              </div>
              <div>
                <Box>
                  <label className="black text-sm">Plan Duration</label>
                  &nbsp;<span style={{ color: "red" }}>*</span>
                </Box>

                <RHFTextField
                  name="planDuration"
                  placeholder="Plan Duration"
                  type="text"
                  fullWidth
                  required
                />
              </div>

              <div>
                <label className="black text-sm">Plan Highlights</label>
                <RHFTextField
                  name="planHighlights"
                  placeholder="Plan Highlights"
                  type="text"
                  fullWidth
                  // required
                />
              </div>
            </div>
            <div className="grid grid-cols-3 gap-3">
              {/* <div>
                <label className="black text-sm">Tax Percentage</label>
                <RHFTextField
                  name="taxPercentage"
                  placeholder="Tax Percentage"
                  type="number"
                  fullWidth
                  required
                />
              </div> */}
              {/* <div>
                <label className="black text-sm">Valid Days</label>
                <RHFTextField
                  name="validityDays"
                  placeholder="Valid Days"
                  type="number"
                  fullWidth
                  required
                />
              </div> */}
            </div>
            <div className="flex justify-end">
              <div>
                <Button
                  type="submit"
                  // sx={{ color: "#ffffff", backgroundColor: "#FD8D4C", marginTop: "10px" }}
                  variant="submit"
                >
                  Submit
                </Button>
              </div>
            </div>
          </Box>
        </Card>
      </form>
    </FormProvider>
  );
};

export default Index;
