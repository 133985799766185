import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import PropTypes from "prop-types";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import RHFTextField from "../../../hook-form/RHFTextField";
import { useDispatch, useSelector } from "react-redux";
import { RHFSelect } from "../../../hook-form/RHFSelect";
import {
  useLazyGetNotificationQuery,
  useAddNotificationMutation,
  useUpdateNotificationMutation,
} from "../../../redux/api/notificationApi";
import {
  addNotificationAction,
  getNotificationAction,
} from "../../../redux/slices/notificationSlice";
import useFetchData from "../../../utility/useFetchData";

const AddNotification = ({ onClose, open, editData, title, setNotificationList }) => {
  const { register, handleSubmit, setValue } = useFormContext();
  const [addNotification] = useAddNotificationMutation();
  const [updateNotification] = useUpdateNotificationMutation();
  const [getNotification] = useLazyGetNotificationQuery();
  useEffect(() => {
    if (editData) {
      setValue("title", editData?.data?.title || "");
      setValue("body", editData?.data?.body || "");
      setValue("device_type", editData?.deviceType || "");
      setValue("user_type_dropdown", editData?.userType || "");
      setValue("notification_type_dropdown", editData?.type || "");
    }
  }, [editData, setValue]);

  const handleSubmitClick = async (data) => {
    const notification = {
      deviceType: data.device_type || "",
      userType: data.user_type_dropdown || "",
      type: data.notification_type_dropdown || "",
      data: {
        title: data.title || "",
        body: data.body || "",
      },
    };

    try {
      if (editData) {
        const updateNotificationResponse = await updateNotification({
          id: editData.id,
          ...notification,
        }).unwrap();
        if (!updateNotificationResponse?.success) {
          toast.error(`${updateNotificationResponse.data.message || "Something went wrong!"} `, {
            style: customToastStyle.error,
          });
        }
        const notificationData = await getNotification({ sortBy: "createdAt:desc" });
        setNotificationList(notificationData?.data?.data);
      } else {
        const notificationResponse = await addNotification(notification).unwrap();
        if (!notificationResponse?.success) {
          toast.error(`${notificationResponse?.data?.message || "Something went wrong!"} `, {
            style: customToastStyle.error,
          });
        }
        const notificationData = await getNotification({ sortBy: "createdAt:desc" });
        setNotificationList(notificationData?.data?.data);
      }
    } catch (err) {
      console.error("Failed to save notification:", err);
    }
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ color: "#FD8D4C" }}>{title}</DialogTitle>
      <form onSubmit={handleSubmit(handleSubmitClick)}>
        <DialogContent>
          <div>
            <div className="flex">
              <h1 className="font-bold">Notification Type</h1>
              &nbsp;<span style={{ color: "red" }}>*</span>
            </div>

            <RHFSelect
              name="notification_type_dropdown"
              options={[
                { label: "transactional", value: "transactional" },
                { label: "promotional", value: "promotional" },
                { label: "informational", value: "informational" },
                { label: "reminder", value: "reminder" },
                { label: "alert", value: "alert" },
                { label: "social", value: "social" },
                { label: "locationBased", value: "locationBased" },
              ]}
              required
            />
          </div>
          <div className="mt-2">
            <div className="flex">
              <h1 className="font-bold">User Type</h1>
              &nbsp;<span style={{ color: "red" }}>*</span>
            </div>

            <RHFSelect
              name="user_type_dropdown"
              options={[
                { label: "all", value: "all" },
                { label: "user", value: "user" },
                { label: "pandit", value: "pandit" },
              ]}
              required
            />
          </div>
          <div className="mt-2">
            <div className="flex">
              <h1 className="font-bold">Device Type</h1>
              &nbsp;<span style={{ color: "red" }}>*</span>
            </div>

            <RHFSelect
              name="device_type"
              options={[
                { label: "all", value: "all" },
                { label: "ios", value: "ios" },
                { label: "android", value: "android" },
              ]}
              required
            />
          </div>
          <div className="w-80">
            <div className="flex">
              <h1 className="font-bold">Title</h1>
              &nbsp;<span style={{ color: "red" }}>*</span>
            </div>
            <RHFTextField name="title" placeholder="Enter Title" type="text" fullWidth required />
          </div>
          <div className="mt-4 w-auto">
            <div className="flex">
              <h1 className="font-bold">Body</h1>
              &nbsp;<span style={{ color: "red" }}>*</span>
            </div>
            <RHFTextField
              name="body"
              placeholder="Enter Sub Title"
              type="text"
              fullWidth
              required
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="submit" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="submit" type="submit">
            Submit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

AddNotification.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  editData: PropTypes.object,
  title: PropTypes.string.isRequired,
};

export default AddNotification;
