import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "./styles/styles.css";

const RichTextEditor = ({ value, onChange }) => {
  return (
    <CKEditor
      editor={ClassicEditor}
      data={value}
      onChange={(event, editor) => {
        const data = editor.getData();
        onChange(data);
      }}
      config={{
        toolbar: ["bold", "italic", "underline", "numberedList", "link", "uploadImage", "emoji"],
        toolbarLocation: "bottom",
      }}
      style={{ minHeight: "90px" }}
    />
  );
};

export default RichTextEditor;
