import React from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import PanditProfile from "../../../assets/images/panditProfile.jpeg";
import { Rating, Stack } from "@mui/material";

export default function assignPanditTableData(bookingList) {
  const Author = ({ name, contact, lastname }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDBox lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="regular">
          {name} {lastname}
        </MDTypography>
        <MDTypography variant="caption">{contact}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const columns = [
    { Header: "user-details", accessor: "userdetails", align: "left" },
    { Header: "order-id", accessor: "orderid", align: "left" },
    { Header: "yajyaname", accessor: "yajyaname", align: "center" },
    { Header: "date", accessor: "date", align: "center" },
    { Header: "time", accessor: "time", align: "center" },
    { Header: "amount", accessor: "amount", align: "center" },
    { Header: "status", accessor: "status", align: "center" },
    { Header: "rating", accessor: "rating", align: "center" },
    { Header: "pandit-details", accessor: "panditdetails", align: "left", width: "25%" },
  ];

  const rows =
    bookingList?.results?.length > 0
      ? bookingList.results.map((item) => {
          const estimReachTime = new Date(item?.order?.estimReachTime);
          const formattedDate = estimReachTime.toLocaleDateString();
          const formattedTime = estimReachTime.toLocaleTimeString();

          return {
            panditdetails: (
              <div className="w-72 overflow-y-scroll h-24">
                {(item?.panditUsers || []).map((items, index) => (
                  <div key={index}>
                    <div className="flex justify-between">
                      <MDBox display="flex" alignItems="center" lineHeight={1}>
                        <MDAvatar src={items?.user?.profileImage || PanditProfile} size="sm" />
                        <MDBox display="flex" justifyContent="space-between" lineHeight={1} ml={2}>
                          <div>
                            <MDTypography display="block" variant="button" fontWeight="medium">
                              {items?.user?.firstName || "-"} {items?.user?.lastName || "-"}
                            </MDTypography>
                            <MDTypography variant="caption">
                              {items?.user?.phone || "-"}
                            </MDTypography>
                          </div>
                        </MDBox>
                      </MDBox>
                      <MDTypography
                        style={{
                          color:
                            items?.status === "pending"
                              ? "#ea580c"
                              : items?.status === "accepted"
                              ? "green"
                              : items?.status === "completed"
                              ? "green"
                              : items?.status === "cancelled"
                              ? "#FF0000"
                              : "",
                          fontSize: "0.8rem",
                        }}
                      >
                        {items?.status || "-"}
                      </MDTypography>
                    </div>
                    <hr className="my-2 text-black" />
                  </div>
                ))}
              </div>
            ),
            userdetails: (
              <Author
                name={item?.order?.orderedUser?.firstName || "-"}
                lastname={item?.order?.orderedUser?.lastName || "-"}
                contact={item?.order?.orderedUser?.phone || "-"}
              />
            ),
            amount: (
              <MDTypography
                component="a"
                href="#"
                variant="caption"
                color="text"
                fontWeight="medium"
              >
                {item?.order?.amount || "-"}
              </MDTypography>
            ),
            orderid: (
              <MDTypography
                component="a"
                href="#"
                variant="caption"
                color="text"
                fontWeight="medium"
              >
                {item?.order?._id || "-"}
              </MDTypography>
            ),
            yajyaname: (
              <MDTypography
                component="a"
                href="#"
                variant="caption"
                color="text"
                fontWeight="medium"
              >
                {item?.order?.plan?.name || "-"}
              </MDTypography>
            ),
            date: (
              <MDTypography
                component="a"
                href="#"
                variant="caption"
                color="text"
                fontWeight="medium"
              >
                {formattedDate || "-"}
              </MDTypography>
            ),
            time: (
              <MDTypography
                component="a"
                href="#"
                variant="caption"
                color="text"
                fontWeight="medium"
              >
                {formattedTime || "-"}
              </MDTypography>
            ),
            status: (
              <MDTypography
                style={{
                  color:
                    item?.order?.status === "completed" || item?.order?.status === "paid"
                      ? "#047857"
                      : "#ea580c",
                  fontSize: "0.8rem",
                }}
              >
                {item?.order?.status || "-"}
              </MDTypography>
            ),
            rating: (
              <Stack spacing={1}>
                <Rating
                  name="half-rating"
                  defaultValue={item?.ratings?.rating || 0}
                  size="small"
                  readOnly
                  precision={0.5}
                />
              </Stack>
            ),
          };
        })
      : [
          {
            userdetails: (
              <MDTypography
                variant="h6"
                color="text"
                fontWeight="medium"
                style={{ textAlign: "center", padding: "20px", width: "100%" }}
                colSpan={columns.length}
              >
                No Data Found
              </MDTypography>
            ),
          },
        ];

  return {
    columns,
    rows,
  };
}
