import React from "react";
import { useLocation } from "react-router-dom";
import Card from "@mui/material/Card";
import ProfileImage from "../../../assets/images/bg-profile.jpeg";
import Rating from "@mui/material/Rating";
import { Typography, Box } from "@mui/material";

const PanditProfile = () => {
  const location = useLocation();
  const { item } = location.state || {};
  console.log(item, "item");

  return (
    <Box m={2}>
      <Card>
        <Box p={4}>
          <div
            className="w-full h-80 bg-cover bg-center relative"
            style={{
              backgroundImage: `url(${
                item?.coverPhoto ||
                "https://t3.ftcdn.net/jpg/05/72/87/18/240_F_572871809_UNgcz3vQ33ckPyQHGWjxlu0gA1lTalR1.jpg"
              })`,
            }}
          >
            <div className="h-full flex items-end p-4 relative">
              <img
                className="w-36 h-36 rounded-full absolute bottom-0 left-4 transform translate-y-1/3 border-4 border-white"
                src={item?.profileImage || ProfileImage}
                alt="Profile"
              />
            </div>
          </div>

          <Box mt={7}>
            <div className="grid grid-cols-4 gap-3">
              <Typography variant="h6" component="h2" className="font-bold">
                Name:{" "}
                <span className="font-normal text-sm">
                  {item?.firstName} {item?.lastName}
                </span>
              </Typography>
              <Typography variant="h6" component="h2" className="font-bold">
                Email: <span className="font-normal text-sm">{item.email}</span>
              </Typography>
              <Typography variant="h6" component="h2" className="font-bold">
                Contact Number:{" "}
                <span className="font-normal text-sm">
                  +{item?.dialCode} {item?.phone}
                </span>
              </Typography>
              <Typography variant="h6" component="h2" className="font-bold">
                Rating:{" "}
                <span className="font-normal text-sm">
                  <Rating
                    size="small"
                    name="half-rating-read"
                    value={item?.avgRating}
                    precision={0.5}
                    readOnly
                  />
                </span>
              </Typography>
              <Typography variant="h6" component="h2" className="font-bold">
                Available Time:{" "}
                <span className="font-normal text-sm">
                  {item?.availableTime?.from} to {item?.availableTime?.to}{" "}
                  <span
                    className={`${item?.availableTime?.is24x7 ? "text-green-700" : "text-red-700"}`}
                  >
                    {item?.availableTime?.is24x7 ? "Available 24/7" : "Not Available 24/7"}
                  </span>
                </span>
              </Typography>
              <Typography variant="h6" component="h2" className="font-bold">
                Languages:{" "}
                <span className="font-normal text-sm">
                  {item.language.map((lang, index) => (
                    <span key={lang.id}>
                      {lang?.name}
                      {index < item?.language?.length - 1 ? ", " : ""}
                    </span>
                  ))}
                </span>
              </Typography>
              <Typography variant="h6" component="h2" className="font-bold">
                Document Verification:{" "}
                <span className="font-normal text-sm">
                  <span className={item?.isDocumentVerified ? "text-green-600" : "text-red-700"}>
                    {item?.isDocumentVerified ? "Verified" : "Not Verified"}
                  </span>
                </span>
              </Typography>
              <Typography variant="h6" component="h2" className="font-bold">
                Plan Active:{" "}
                <span className="font-normal text-sm">
                  <span className={item?.isPlanActiv ? "text-green-600" : "text-red-700"}>
                    {item?.isPlanActiv ? "Plan Is Active" : "Not Active"}
                  </span>
                </span>
              </Typography>
              <Typography variant="h6" component="h2" className="font-bold">
                Offered Services:{" "}
                <span className="font-normal text-sm">
                  {item?.offeredServices.map((service, index) => (
                    <span key={index}>
                      {service?.name}
                      {index < item?.language?.length - 1 ? ", " : ""}
                    </span>
                  ))}
                </span>
              </Typography>
              <Typography variant="h6" component="h2" className="flex items-start font-bold">
                <span className="mr-2">Addresses:</span>
                <span className="font-normal mt-1 text-sm">
                  {item.address.map((address, index) => (
                    <div key={index}>
                      {index + 1}. {address?.address}
                    </div>
                  ))}
                </span>
              </Typography>
            </div>
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

export default PanditProfile;
