// dashboardReducers.js
const getDashboard = (state, { payload }) => {
  state.dashboard = payload;
};

const refreshDashboard = (state) => {
  console.log("🚀 ~ refreshDashboard ~ state:", state?.refreshDashboard);
  state.refreshDashboard = true;
};

export { getDashboard, refreshDashboard };
