import Dashboard from "layouts/dashboard";
import Banner from "layouts/banner";
import Category from "layouts/yajya/category";
import WalletTransaction from "layouts/walletTransaction";
import AssignPandit from "layouts/assignPandit";
import Pandits from "layouts/pandits";
import Samagri from "layouts/samagri";
import Notification from "layouts/notification";
import City from "layouts/city";
import AddSubCategory from "layouts/addYajya/addSubCategory";
import AddPandit from "layouts/pandits/addPandit";
import PanditProfile from "layouts/pandits/panditProfile";
import AddSamagri from "layouts/samagri/addSamagri";
import SubCategory from "layouts/yajya/subCategory";
import SignIn from "layouts/authentication/sign-in";
import Language from "layouts/language";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: "dashboard",
    route: "/dashboard",
    component: Dashboard,
    auth: true,
  },
  {
    type: "collapse",
    name: "Banner",
    key: "banner",
    icon: "branding_watermark",
    route: "/banner",
    component: Banner,
    auth: true,
  },
  {
    type: "collapse",
    name: "Yajya Management",
    key: "yajya",
    icon: "local_fire_department",
    route: "/yajya",
    collapse: [
      {
        type: "collapse",
        name: "Category",
        key: "Category",
        icon: "label_outline",
        route: "/yajya/category",
        component: Category,
        auth: true,
      },
      {
        type: "collapse",
        name: "Yajya",
        key: "sub-Category",
        icon: "label_outline",
        route: "/yajya/sub-category",
        component: SubCategory,
        auth: true,
      },
    ],
  },
  {
    type: "collapse",
    name: "Wallet Transactions",
    key: "wallet-transactions",
    icon: "account_balance_wallet",
    route: "/wallet-transactions",
    component: WalletTransaction,
    auth: true,
  },
  {
    type: "collapse",
    name: "Bookings",
    key: "assign-pandit",
    icon: "assignment_ind",
    route: "/assign-pandit",
    component: AssignPandit,
    auth: true,
  },
  {
    type: "collapse",
    name: "Pandits",
    key: "Pandits",
    icon: "group",
    route: "/pandits",
    component: Pandits,
    auth: true,
  },
  {
    type: "collapse",
    name: "Samagri",
    key: "Samagri",
    icon: "inventory",
    route: "/samagri",
    component: Samagri,
    auth: true,
  },
  {
    type: "collapse",
    name: "City",
    key: "City",
    icon: "location_city",
    route: "/city",
    component: City,
    auth: true,
  },
  {
    type: "collapse",
    name: "Language",
    key: "Language",
    icon: "language",
    route: "/language",
    component: Language,
    auth: true,
  },
  {
    type: "collapse",
    name: "Notification",
    key: "Notification",
    icon: "notifications",
    route: "/notification",
    component: Notification,
    auth: true,
  },

  {
    route: "/yajya/sub-category/add-sub-category",
    component: AddSubCategory,
    auth: true,
  },
  {
    route: "/pandits/add-pandit",
    component: AddPandit,
    auth: true,
  },
  {
    route: "/pandits/pandit-profile",
    component: PanditProfile,
    auth: true,
  },
  {
    route: "/samagri//add-samagri",
    component: AddSamagri,
    auth: true,
  },
  {
    route: "/authentication/sign-in",
    component: SignIn,
    auth: false,
  },
];

export default routes;
