import { apiSlice } from ".";
import URLS from "../constants/api";

export const dashboardApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDashboard: builder.query({
      query: ({ sortBy }) => `${URLS.DASHBOARD}?sortBy=${sortBy}`,
      providesTags: (result) => [{ type: "Dashboard" }],
    }),
  }),
});

export const { useLazyGetDashboardQuery } = dashboardApiSlice;
