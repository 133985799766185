import React from "react";
import { Card, Box, Typography } from "@mui/material";
import RHFTextField from "../../../hook-form/RHFTextField";
import { Button } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { Icon } from "@iconify/react";

const Cards = ({ title, dataKey, items, setItems, isUpdate }) => {
  const { getValues, setValue } = useFormContext();

  const handleAddClick = () => {
    const currentValues = getValues();
    const newItem = {
      name: currentValues[`${dataKey}Name`] || "",
      quantity: currentValues[`${dataKey}Quantity`] || "",
    };

    if (newItem.name && newItem.quantity) {
      setItems((prevItems) => [...prevItems, newItem]);
      setValue(dataKey, [...items, newItem]);
    }
  };
  const handleDeleteClick = (index) => {
    const updatedItems = items.filter((_, i) => i !== index);
    setItems(updatedItems);
    setValue(dataKey, updatedItems);
  };

  return (
    <Card sx={{ padding: "20px", marginTop: "20px" }}>
      <Typography variant="h6">
        {title} Samgiri &nbsp;<span style={{ color: "red" }}>*</span>
      </Typography>
      {items.map((item, index) => (
        <div key={index} className="flex items-center w-full justify-between mt-4">
          <div className="flex space-x-4 ">
            {" "}
            <Typography variant="listItems">{index + 1}.</Typography>
            <Typography variant="listItems">{item.name}</Typography>
            <Typography variant="listItems">{item.quantity}</Typography>
          </div>
          <Icon
            className="text-red-800 h-4"
            icon="ep:delete"
            onClick={() => handleDeleteClick(index)}
          />
        </div>
      ))}
      <div className="gap-5 mt-3 grid grid-cols-2">
        <Box sx={{ flex: 1 }}>
          <Typography variant="subtitle1" fontSize={15}>
            Name
          </Typography>
          <RHFTextField
            name={`${dataKey}Name`}
            placeholder="Enter Name"
            type="text"
            fullWidth
            required={!isUpdate}
          />
        </Box>
        <Box sx={{ flex: 1 }}>
          <Typography variant="subtitle1" fontSize={15}>
            Quantity
          </Typography>
          <RHFTextField
            name={`${dataKey}Quantity`}
            placeholder="Enter Quantity"
            type="number"
            fullWidth
            required={!isUpdate}
          />
        </Box>
      </div>
      <div className="mt-4 flex justify-center">
        <Button type="button" onClick={handleAddClick} variant="submit">
          Add
        </Button>
      </div>
    </Card>
  );
};

export default Cards;
