import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";
import RHFTextField from "../../../hook-form/RHFTextField";
import { useDispatch, useSelector } from "react-redux";
import { RHFSelect } from "../../../hook-form/RHFSelect";
import { useAddLanguageMutation } from "../../../redux/api/languageApi";
import { addLanguageAction } from "../../../redux/slices/languageSlice";
import { useLazyGetLanguageQuery } from "../../../redux/api/languageApi";
import toast from "react-hot-toast";
import { customToastStyle } from "../../../redux/api";

const AddLanguage = ({ onClose, open, title, setLanguageList }) => {
  const { handleSubmit } = useFormContext();
  const [getLanguage] = useLazyGetLanguageQuery();

  const [addLanguage] = useAddLanguageMutation();

  const handleSubmitClick = async (data) => {
    const language = {
      name: data.name,
    };

    try {
      const languageResponse = await addLanguage(language);
      if (!languageResponse?.data?.success)
        toast.error(`${languageResponse.data.message || "Something went wrong!"} `, {
          style: customToastStyle.error,
        });

      const languageData = await getLanguage({ sortBy: "createdAt:desc" });
      if (languageData?.data?.success) {
        setLanguageList(languageData?.data?.data);
      }
      onClose();
    } catch (err) {
      console.error("Failed to add language:", err);
    }
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ color: "#FD8D4C", width: "440px" }}>{title}</DialogTitle>
      <form onSubmit={handleSubmit(handleSubmitClick)}>
        <DialogContent>
          <div className="">
            <div className="flex">
              <h1 className="font-bold">Name</h1>
              &nbsp;<span style={{ color: "red" }}>*</span>
            </div>

            <RHFTextField
              name="name"
              placeholder="Enter Language Name"
              type="text"
              fullWidth
              required
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="submit" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="submit" type="submit">
            Submit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

AddLanguage.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  body: PropTypes.string,
  title: PropTypes.string,
};

export default AddLanguage;
